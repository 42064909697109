import messages from "@/helpers/validate/messages";
import { CustomerService as service } from "@/services/customer/customer.services";
import store from "@/store";
import { computed } from "vue";

import * as yup from "yup";

const state = computed(() => store?.state.accountReceivable).value;

const browsing = { ...store?.state.login.browsing };

export default yup.object({
  competitionDate: yup
    .string()
    .test(
      "competitionDate",
      messages.compile({ id: "required", value: "Data competência" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  customer: yup
    .object()
    .test(
      "customer",
      messages.compile({ id: "required", value: "Cliente" }),
      (value: any) => {
        const result = !!value?.id;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  financialCategory: yup
    .number()
    .test(
      "financialCategory",
      messages.compile({ id: "required", value: "Categoria" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  description: yup
    .string()
    .test(
      "description",
      messages.compile({ id: "required", value: "Descrição" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  duedate: yup
    .string()
    .test(
      "duedate",
      messages.compile({ id: "required", value: "Data vencimento" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  installmentvalue: yup
    .number()
    .test(
      "installmentvalue",
      messages.compile({ id: "required", value: "Valor" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  meansofpayment: yup
    .number()
    .test(
      "meansofpayment",
      messages.compile({ id: "required", value: "Meio de pagamento" }),
      (value: any) => {
        if (!store.state.accountReceivable.accountReceivable.newBankslip)
          return true;

        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  financialaccount: yup
    .number()
    .test(
      "financialaccount",
      messages.compile({ id: "required", value: "Conta" }),
      (value: any) => {
        if (!store.state.accountReceivable.accountReceivable.newBankslip)
          return true;

        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),
});
