import messages from "@/helpers/validate/messages";
import { CustomerService as service } from "@/services/customer/customer.services";
import store from "@/store";
import { computed } from "vue";

import * as yup from "yup";

const state = computed(() => store?.state?.sale).value;

export default yup.object({
  customer: yup
    .object()
    .test(
      "customer",
      messages.compile({ id: "required", value: "Cliente" }),
      (value: any) => !!value?.id
    )
    .test(
      "customer",
      messages.compile({ id: "saleCustomer", value: "" }),
      async (value: any) => {
        const customer: any = await service.getById(
          state.sale.ClienteIdCriptografado
        );

        if (!customer.Cpf) return false;

        if (!customer.address) return false;

        if (customer.DocumentoNf) {
          if (!customer.addressnf) return false;
        }

        if (customer.contacts) {
          let hasEmail = false;
          for (const contact of customer.contacts) {
            if (contact.contacttypeDescription == "Email") hasEmail = true;
          }
          return hasEmail;
        } else {
          return false;
        }
      }
    ),

  employee: yup
    .number()
    .test(
      "employee",
      messages.compile({ id: "required", value: "Colaborador" }),
      (value: any) => {
        return !!value;
      }
    ),

  saleitem: yup
    .array()
    .test(
      "saleitem",
      messages.compile({ id: "requiredList", value: "de itens da venda" }),
      (value: any) => {
        return value ? !!value.length : !!value;
      }
    ),

  accountReceivable: yup
    .array()
    .test(
      "accountReceivable",
      messages.compile({ id: "requiredList", value: "de contas a receber" }),
      (value: any) => {
        return value ? !!value.length : !!value;
      }
    ),
  financialBalance: yup.number().test("financialBalance", function (value) {
    if (value) {
      const difference = Math.abs(value) === 0 ? 0 : value?.toFixed(2);

      return (
        value?.toFixed(2) == "0.00" ||
        value?.toFixed(2) == "-0.00" ||
        this.createError({
          message: `A soma das parcelas não está de acordo com o valor da venda. Diferença de: ${difference}`,
        })
      );
    }
    return !value;
  }),

  financialaccount: yup
    .number()
    .typeError(messages.compile({ id: "required", value: "Conta" }))
    .test(
      "financialaccount",
      messages.compile({ id: "required", value: "Conta" }),
      (value: any) => {
        return !!value;
      }
    ),
});
